.chalo-button-container {
    font-family: "Noto Sans",sans-serif;
    display: inline-flex;
    background-color: #F47C21;
    padding: 0.85rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid #F47C21;
    width: 100%;
}

.chalo-button-disabled {
    opacity: 40%;
}

.chalo-button-container:hover {
    cursor: pointer;
}

.chalo-button-container a {
    text-decoration: none;
    line-height: 1;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: white;
}

.chalo-button-container a span {
    vertical-align: middle;
    font-size: medium;
    font-weight: 700;
}
