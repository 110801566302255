.cDropdown-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #EBEBEB;
    height: 3.5rem;
    justify-content: center;
    border-radius: 0.5rem;
    position: relative;
}

.cDropdown-label {
    font-size: 0.875rem;
    color: #808080;
    padding: 0;
    margin: 0;
    font-weight: normal;
    margin-left: 0.1875rem;
    position: absolute;
    top: 0.25rem;
    left: 0.5625rem;
    pointer-events: none;
}

.cDropdown-field {
    width: 100%;
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    line-height: normal;
    background-color: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 100%;
    padding: 0 0.75rem;
}

.cDropdown-field:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: none;
    background-color: transparent;
    -webkit-appearance: none !important;
}

.cDropdown-field-disabled {
    background-color: #F8F8F8;
    border: #F8F8F8;
}

.cDropdown-field:disabled {
    background-color: transparent;
}

.cRequired {
    color: red;
}

.cText-grey {
    color: #808080;
}

.cSelect-position {
    padding: 1.5rem 0.75rem 0;
}

.cDisplay-none {
    display: none;
}

.cDropdown-arrow {
    position: absolute;
    right: 0.9375rem;
    font-weight: 700;
    font-size: 1.25rem;
    color: #323232;
    pointer-events: none;
}
