.error-dialog-content {
  font-family: "Noto Sans",sans-serif;
  background-color: white;
  margin: auto;
  padding: 0 1.25rem 1.25rem;
  width: 90%;
  max-width: 25.5rem;
  border-radius: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  top: 40%;
}

.error-dialog-content h3 {
  font-weight: bold;
}

.error-dialog-content button {
  align-self: flex-end;
  border: none;
  background-color: transparent;
  color: #F57C00;
  font-weight: 700;
  margin-top: 0.8rem;
}
