
/* nav bar styling  */
.qr-navbar{
    font-family: "Noto Sans",sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 4rem;
    border-bottom: 1px solid rgb(196, 196, 196);
}
.back-button {
    border: none;
    margin-right: 5px;
    padding: 0;
    background: none;
    height: 50px;
}

.back-button i {
    font-size: 20px;
    margin: 0 10px 0 20px;
}

.navbar-heading {
    margin: 0;
    font-size: x-large;
    font-weight: 700;

}