.add-camera-container {
    font-family: "Noto Sans",sans-serif;
    height: 70%;
    width: 100%;
    max-width: 25rem;
    margin: auto;
}

.add-camera-container h3{
    font-weight: 700;
    font-size: x-large;
    margin-top: 2rem;
}

.add-camera-content {
    background-color: white;
    margin: 10% auto;
    padding: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0rem 0.125rem 0.5rem 0rem;
    border-radius: 0.375rem;
}

.scan-qr-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
}
.scan-qr-content p{
    font-weight: 700;
    font-size: medium;
    padding: 0;
    margin: 0;
}

.custom-hr {
    border: 0;
    border-top: 0.0625rem solid rgb(207, 207, 207);
    margin: 2rem 0;
}

.camera-id-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.camera-id-form p{
    padding: 0;
    margin: 0;
    font-size: medium;
    font-weight: 400;
}

.error-dialog-container {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 3.75rem;
    align-content: center;
} 

.camera-wrapper{
    font-family: "Noto Sans",sans-serif;
    background-color: white;
    height: calc(100vh - 60px);
}

.camera-details-container{
    background-color: white;
    display: flex;
    flex-direction: column;
}

.camera-detail-content {
    max-width: 30rem;
    margin: 0 auto;
    width: 100%;
    padding: 1.25rem;
    margin-bottom: 5rem;
}

.camera-detail-form {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.camera-confirm-form {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
}

.passed-message-container {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.875rem;
}

.passed-message-container h4 {
    font-weight: 700;
    font-size: large;
    padding: 0;
    margin: 0;
}

.passed-message-container p {
    border-radius: 0.25rem;
    padding: 0 0.4625rem;
    margin: 0;
    font-size: small;
    font-weight: 400;
}

.passed-message-container .Passed {
    background-color: #E0F5E3;
    color: #35AE45;
}

.passed-message-container .failed {
    background-color: #fcdcdc;
    color: #f01313;
}

.camera-detail-form h4 {
    font-size: large;
    font-weight: 700;
    padding: 0;
    margin: 0;

}

.camera-confirm-form h4{
    font-size: large;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.camera-error-text {
    color: #f01313;
    margin-left: 0.625rem;
    font-size: small;
}

.feild-detail h6 {
    font-size: medium;
    font-weight: 400;
    margin: 0;
    padding: 0 0 0.3rem;
}

.feild-detail p {
    font-weight: 700;
    font-size: medium;
    padding: 0;
    margin: 0;
}

.live-feed-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 3.75rem);
}

.live-feed-content {
    max-width: 30rem;
    margin: 0 auto;
    width: 100%;
}

.feed-heading {
    font-size: large;
    font-weight: 700;
    padding: 0;
    margin: 0;
    padding-bottom: 1rem;
}

.live-feed {
    border-radius: 0.625rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    overflow: hidden;
    margin-bottom: 2rem;
    max-width: 31rem;
    aspect-ratio: 16 / 9;
}

.live-feed-canva {
    width: 100%;
    object-fit: fill;
}

.canva-failed {
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #EBEBEB;
    align-content: center;
    position: absolute;
    top: 0;
}

.live-feed-control {
    width: 100%;
    height: 2.5rem;
    text-align: end;
    padding: 0.3125rem 1.25rem;
}

.feed-msg {
    padding: 0.625rem 0.625rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.625rem;
}

.feed-msg span{
    font-size: small;
    font-weight: 400;
}

.feed-success-msg {
    background-color: #E0F5E3;
}

.feed-failed-msg {
    background-color: #FAE6E6;
}

.feed-success-msg i {
    color: green;
    margin-right: 0.625rem;
}

.feed-failed-msg i {
    color: #C62828;
    margin-right: 0.625rem;
}

.live-feed-footer {
    width: 100%;
    max-width: 30rem;
    margin: auto;
    padding: 0.9375rem;
}

.camera-success-footer{
    width: 100%;
    max-width: 30rem;
    margin: auto;
    padding: 0.9375rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 1.5rem;
}

@media (max-width: 676px) {
    .live-feed-container{
        position: relative;
        height: calc(100vh - 60px)
    }
}

@media (max-width: 480px) {
    .live-feed-footer{
        position: fixed;
        bottom: 0;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3); /* Top-only shadow */
        background-color: white;
    }
  }

.scanner-container{
    height: 100%;
    background-color: white;
    font-family: "Noto Sans",sans-serif;
}

.qr-reader {
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: calc(100vh );
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-box .scan-region-highlight {
    height: 0px !important;
}
.qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(0);
}

.scan-brief{
    position: absolute;
    bottom: 20%;
    color: white;
}

.full-screen-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.camera-success{
    font-family: "Noto Sans",sans-serif;
    height: calc(100vh - 60px);
    padding: 1rem;
    position: relative;
}
.camera-success-message{
    width: 100%;
    height: 100%;
    align-content: center;
}

.camera-success-message h2{
    font-size:x-large;
    font-weight: 700;
    justify-self: center;
}

@media (max-width: 480px) {
    .scanner-container{
        height: 90vh;
    }

    .qr-reader {
        width: 100%;
        height: 100%;
    }

    .qr-reader .qr-frame {
        transform: translateX(-50%) translateY(-10%);
    }
    .scan-region-highlight{
        position: absolute;
        width: 65vw !important;
        height: 65vw !important;
        top: 20vh !important;
        left: auto !important;
    }
    .scan-brief{
        position: absolute;
        bottom: 20%;
    }
}

@media (min-width: 480px) and (max-width: 768px){
    .scan-region-highlight{
        position: absolute;
        width: 40vh !important;
        height: 40vh !important;
        top: 20vh !important;
        left: auto !important;
    }
}
@media (min-width: 768px){
    .scan-region-highlight{
        position: absolute;
        width: 40vh !important;
        height: 40vh !important;
        top: 20vh !important;
        left: auto !important;
    }
    .scan-brief{
        position: absolute;
        bottom: 20%;
    }
}